
.card {
  width: 95%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.headingContent {
  display: flex;
  vertical-align: middle;
  p {
    margin: 10px;
    padding: 10px;
  }
}